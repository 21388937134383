<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <a-row :gutter="24" type="flex">
      <a-col :span="24" class="mb-24">
        <CardAuthorTable
          :data="table1Data"
          :columns="lang == 'uz' ? table1Columns : table1ColumnsRu"
          @filterGender="filterGender"
        ></CardAuthorTable>
      </a-col>
    </a-row>

    <!-- <a-row :gutter="24" type="flex">
      <a-col :span="24" class="mb-24">
        <CardProjectTable2
          :data="table2Data"
          :columns="table2Columns"
        ></CardProjectTable2>
      </a-col>
    </a-row> -->
  </div>
</template>

<script>
import axios from "../axios.js";
// "Authors" table component.
import CardAuthorTable from "../components/Cards/CardAuthorTable";

// "Projects" table component.
import CardProjectTable2 from "../components/Cards/CardProjectTable2";

// "Authors" table list of columns and their properties.
const table1Columns = [
  {
    title: "RASMI",
    dataIndex: "avatar",
    scopedSlots: { customRender: "avatar" },
  },
  {
    title: "ISMI",
    dataIndex: "firstName",
  },
  {
    title: "FAMILIYASI",
    dataIndex: "lastName",
  },
  {
    title: "SHARIFI",
    dataIndex: "middleName",
  },
  {
    title: "JINSI",
    dataIndex: "gender",
    // class: 'text-primary',
    scopedSlots: { customRender: "sex_color" },
  },
  {
    title: "TUG'ILGAN SANASI",
    dataIndex: "birthDate",
    class: "text-muted",
    width: 160,
  },
  {
    title: "MANZILI",
    dataIndex: "address",
    class: "text-muted",
  },
  {
    title: "YARATILGAN SANA",
    dataIndex: "createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
  },
  {
    title: "",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
];

const table1ColumnsRu = [
  {
    title: "ФОТО",
    dataIndex: "avatar",
    scopedSlots: { customRender: "avatar" },
  },
  {
    title: "ИМЯ",
    dataIndex: "firstName",
  },
  {
    title: "ФАМИЛИЯ",
    dataIndex: "lastName",
  },
  {
    title: "ОТЧЕСТВО",
    dataIndex: "middleName",
  },
  {
    title: "ПОЛ",
    dataIndex: "gender",
    // class: 'text-primary',
    scopedSlots: { customRender: "sex_color" },
  },
  {
    title: "ДАТА РОЖДЕНИЯ",
    dataIndex: "birthDate",
    class: "text-muted",
    width: 160,
  },
  {
    title: "АДРЕС",
    dataIndex: "address",
    class: "text-muted",
  },
  {
    title: "ДАТА СОЗДАНИЯ",
    dataIndex: "createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
  },
  {
    title: "",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
];

// "Projects" table list of columns and their properties.
const table2Columns = [
  {
    title: "COMPANIES",
    dataIndex: "company",
    scopedSlots: { customRender: "company" },
    width: 300,
  },
  {
    title: "BUDGET",
    dataIndex: "budget",
    class: "font-semibold text-muted",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    class: "font-semibold text-muted text-sm",
  },
  {
    title: "COMPLETION",
    scopedSlots: { customRender: "completion" },
    dataIndex: "completion",
  },
  {
    title: "",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
];

// "Projects" table list of rows and their properties.
const table2Data = [
  {
    key: "1",
    company: {
      name: "Spotify Version",
      logo: "images/logos/logo-spotify.svg",
    },
    status: "working",
    budget: "$14,000",
    completion: 60,
  },
  {
    key: "2",
    company: {
      name: "Progress Track",
      logo: "images/logos/logo-atlassian.svg",
    },
    status: "working",
    budget: "$3,000",
    completion: 10,
  },
  {
    key: "3",
    company: {
      name: "Jira Platform Errors",
      logo: "images/logos/logo-slack.svg",
    },
    status: "done",
    budget: "Not Set",
    completion: {
      status: "success",
      value: 100,
    },
  },
  {
    key: "4",
    company: {
      name: "Launch new Mobile App",
      logo: "images/logos/logo-spotify.svg",
    },
    status: "canceled",
    budget: "$20,600",
    completion: {
      status: "exception",
      value: 50,
    },
  },
  {
    key: "5",
    company: {
      name: "Web Dev",
      logo: "images/logos/logo-webdev.svg",
    },
    status: "working",
    budget: "$4,000",
    completion: 80,
  },
  {
    key: "6",
    company: {
      name: "Redesign Online Store",
      logo: "images/logos/logo-invision.svg",
    },
    status: "canceled",
    budget: "$2,000",
    completion: {
      status: "exception",
      value: 0,
    },
  },
];
const myData = [];
export default {
  components: {
    CardAuthorTable,
    CardProjectTable2,
  },
  data() {
    return {
      // Associating "Authors" table data with its corresponding property.
      table1Data: [],
      lang: null,
      // Associating "Authors" table columns with its corresponding property.
      table1Columns: table1Columns,
      table1ColumnsRu: table1ColumnsRu,

      // Associating "Projects" table data with its corresponding property.
      table2Data: table2Data,

      // Associating "Projects" table columns with its corresponding property.
      table2Columns: table2Columns,
    };
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
    axios.get("/api/patients?size=700&sort=createdAt,desc").then((res) => {
      res.data._embedded.patients.forEach((item) => {
        // console.log(item);
        let data = {
          key: "1",
          avatar: {
            photo: "images/face-3.jpg",
          },
          first_name: item.firstName,
          last_name: item.lastName,
          surname: item.middleName,
          sex: item.gender == 1 ? "ERKAK" : "AYOL",
          birth_date: item.birthDate,
          address: item.address,
          status: 1,
        };
      });
      this.table1Data = res.data._embedded.patients;
    });
    // for (let k = 0; k < res.data._embedded.patients.length; k++) {
    //   (this.table1Data[k].avatar = {
    //     photo: "images/face-3.jpg",
    //   }),
    //     (this.table1Data[k].key = k + 1);
    //   this.table1Data[k].first_name =
    //     res.data._embedded.patients[k].firstName;
    //   this.table1Data[k].last_name = res.data._embedded.patients[k].lastName;
    //   this.table1Data[k].surname = res.data._embedded.patients[k].middleName;
    //   (this.table1Data[k].sex =
    //     res.data._embedded.patients[k].gender == 0 ? "ERKAK" : "AYOL"),
    //     (this.table1Data[k].birth_date =
    //       res.data._embedded.patients[k].birthDate);
    //   this.table1Data[k].status = 1;
    //   this.table1Data[k].address = res.data._embedded.patients[k].address;
    // }
    // console.log(this.table1Data);
  },
  methods: {
    filterGender(val) {
      axios.get("/api/patients?size=700&sort=createdAt,desc").then((res) => {
        if (val == 0) {
          this.table1Data = res.data._embedded.patients;
        } else {
          this.table1Data = res.data._embedded.patients.filter(
            (item) => item.gender == val
          );
        }
      });
    },
  },
};
</script>

<style lang="scss">
</style>